import {ScaleLoader} from 'react-spinners'
import React from "react"

const ComponentSpinner = ({ size = "full-screen" }) => {
  if (size === "small") {
    return (
      <div className='my-2'>
        <ScaleLoader height="20px" width="4px" radius="10px" color="#06b5d7" />
      </div>
    )
  }

  return (
    <div className='fallback-spinner-effect'>
      <ScaleLoader height='100px' width='20px' radius='10px' color = "#06b5d7" />
    </div>
  )
}

export default ComponentSpinner
